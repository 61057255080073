@mixin darkmode-variables {
	--backup-files-background: var(--secondary-600);
	--backup-files-button-active-background-color: var(--secondary3);
	--backup-files-button-active-border-color: var(--secondary3);
	--backup-files-button-background-color: var(--secondary-450);
	--backup-files-button-border-color: var(--secondary-450);
	--backup-files-button-color: var(--white);
	--backup-files-button-hover-background-color: var(--secondary4);
	--backup-files-button-hover-border-color: var(--secondary4);
	--backup-files-toolbar-item-active-color: var(--link-active-color);
	--backup-files-toolbar-item-color: var(--link-color);
	--backup-files-toolbar-item-hover-color: var(--link-hover-color);
	--body-background-color: var(--secondary-550);
	--body-text-color: var(--secondary1);
	--body-text-color-light: var(--secondary2);
	--button-alternative-active-color: var(--information1);
	--button-alternative-hover-color: var(--information1);
	--button-primary-active-background-color: var(--secondary3);
	--button-primary-active-border-color: var(--secondary3);
	--button-primary-background-color: var(--secondary);
	--button-primary-border-color: var(--secondary4);
	--button-primary-color: var(--secondary1);
	--button-primary-disabled-background-color: var(--secondary-500);
	--button-primary-disabled-border-color: var(--secondary-450);
	--button-primary-disabled-color: var(--secondary-450);
	--button-primary-disabled-opacity: var(--opacity100);
	--button-primary-hover-background-color: var(--secondary-450);
	--button-primary-hover-border-color: var(--secondary);
	--button-primary-hover-color: var(--white);
	--button-secondary-background-color: var(--secondary-550);
	--button-secondary-color: var(--secondary-150);
	--button-secondary-disabled-border-color: var(--white);
	--button-secondary-disabled-color: var(--white);
	--button-secondary-hover-background-color: var(--secondary-450);
	--button-secondary-hover-border-color: var(--secondary);
	--dropdown-arrow-color: var(--secondary-450);
	--dropdown-background-color: var(--secondary-350);
	--dropdown-disabled-background-color: var(--secondary-450);
	--dropdown-focused-arrow-color: var(--secondary);
	--dropdown-focused-border-color: var(--body-text-color);
	--dropdown-hover-arrow-color: var(--secondary-450);
	--dropdown-hover-border-color: var(--body-text-color);
	--drop-up-down-flyout-normal-color: var(--secondary-150);
	--editor-selection-background-color: var(--information1);
	--editor-validation-error-icon-color: var(--error2);
	--edit-panel-background-color: var(--secondary);
	--edit-section-edit-background: var(--secondary-450);
	--flyout-link-selected-background-color: var(--secondary);
	--fm-drop-bg: var(--secondary-450);
	--fm-item-grid-highlight-bg: var(--secondary-450);
	--fm-item-grid-hover-bg: var(--secondary-450);
	--fm-item-grid-hover-color: var(--secondary3);
	--fm-item-grid-icon-hover-color: var(--secondary2);
	--fm-item-grid-select-bg: rgba(255, 255, 255, .8);
	--fm-item-grid-select-color: rgba(255, 255, 255, .5);
	--fm-item-grid-selected-bg: var(--secondary-450);
	--fm-item-list-hover-bg: var(--secondary);
	--fm-item-list-hover-color: var(--secondary-150);
	--fm-item-list-icon-hover-color: var(--secondary-150);
	--fm-item-list-select-color: var(--white);
	--fm-item-list-selected-bg: var(--secondary-450);
	--fm-item-list-thumbnail-bg: var(--secondary-450);
	--fm-item-list-thumbnail-hover-bg: var(--secondary-450);
	--fm-item-preview-icon-background-color: var(--secondary-450);
	--fm-toolbar-item-active-color: var(--brand);
	--fm-toolbar-item-color: var(--secondary3);
	--fm-toolbar-item-hover-color: var(--brand);
	--fm-toolbar-item-selected-color: var(--brand);
	--fm-toolbar-separator-color: var(--secondary3);
	--fm-view-mode-selected-color: var(--brand);
	--fm-view-mode-tile-border-color: var(--secondary-250);
	--fm-view-mode-tile-color: var(--secondary3);
	--form-background-color: var(--secondary-450);
	--form-message-error-color: var(--error2);
	--icon-checked-color: var(--success2);
	--icon-highlight-color: var(--brand);
	--icon-muted-color: var(--secondary-450);
	--icon-default-color: var(--secondary-250);
	--icon-radio-selected-color: var(--information1);
	--icon-search-clear-input-color: var(--secondary-350);
	--info-tooltip-icon-color: var(--information1);
	--info-tooltip-icon-hover-color: var(--primary3);
	--level-up-color: var(--brand);
	--link-active-color: var(--secondary-150);
	--link-color: var(--secondary-350);
	--link-hover-color: var(--secondary2);
	--link-icon-active-color: var(--secondary2);
	--link-icon-hover-color: var(--secondary-150);
	--list-header-color: var(--secondary2);
	--list-item-expired-background-color: var(--secondary-450);
	--list-item-icon-color-stronger: var(--secondary2);
	--list-item-invalid-background-color: var(--secondary);
	--menu-background-color: var(--secondary-550);
	--message-attention-border-color: var(--attention3);
	--message-background-color: var(--secondary);
	--message-info-border-color: var(--information1);
	--message-negative-border-color: var(--error2);
	--message-positive-border-color: var(--success2);
	--navigation-active-background-color: var(--secondary-450);
	--navigation-hover-background-color: var(--secondary-450);
	--overlay-border-color: var(--secondary-450);
	--overlay-content-color: var(--secondary-550);
	--overlay-close-icon-on-white-background-active-color: var(--secondary2);
	--overlay-close-icon-on-white-background-color: var(--secondary-550);
	--overlay-close-icon-on-white-background-hover-color: var(--secondary-150);
	--overlay-error-background-color: var(--secondary-550);
	--overlay-error-icon-color: var(--error2);
	--overlay-loading-cover-color: rgba(255, 255, 255, 0.2);
	--password-strength-medium-background-color: var(--attention2);
	--password-strength-none-background-color: var(--secondary-350);
	--password-strength-strong-background-color: var(--success2);
	--password-strength-very-weak-background-color: var(--error2);
	--password-strength-weak-background-color: var(--brand);
	--profile-ssh-key-list-separator: var(--secondary-350);
	--protocol-path-overlay-list-background-color: var(--secondary-450);
	--quota-bar-background-color: var(--secondary-450);
	--quota-segment-separator-color: var(--secondary-450);
	--scroll-top-button-icon: url("client/icons/Up_Scroll_Button_dark.svg");
	--search-menu-border-color: var(--secondary1);
	--search-menu-checkbox-hover-color: var(--brand);
	--search-menu-button-background-hover-color: var(--secondary-450);
	--search-menu-button-background-color: var(--secondary-550);
	--search-menu-button-border-color: var(--secondary1);
	--search-menu-button-border-left-color: var(--secondary1);
	--search-menu-button-color: var(--white);
	--search-menu-caret-down-color: var(--secondary1);
	--search-menu-caret-down-selected-color: var(--secondary1);
	--search-menu-clear-button-border-color: var(--secondary1);
	--search-menu-clear-button-color: var(--secondary1);
	--search-menu-label-color: var(--secondary1);
	--search-menu-label-selected-background-color: var(--secondary);
	--setup-success-icon-color: var(--success2);
	--sfm-footer-background-color: var(--secondary-600);
	--sfm-footer-extra-feedback-active-background-color: var(--secondary);
	--sfm-footer-extra-feedback-hover-background-color: var(--secondary4);
	--sfm-footer-link-active-color: var(--secondary-150);
	--sfm-footer-link-color: var(--secondary-150);
	--sfm-header-color: var(--secondary-600);
	--sfm-upload-detail-overlay-list-background: var(--secondary-550);
	--sfm-upload-detail-overlay-progress-complete-background: var(--secondary-450);
	--sfm-upload-detail-overlay-progress-empty-background: var(--secondary4);
	--sfm-upload-detail-overlay-progress-failed-background: var(--secondary-350);
	--sfm-upload-detail-overlay-progress-running-background: var(--brand);
	--sfm-upload-status-action-icon-color: var(--secondary1);
	--sfm-upload-status-background: var(--secondary-450);
	--sfm-upload-status-complete-color: var(--success2);
	--sfm-upload-status-error-color: var(--error2);
	--sfm-upload-status-icon-color: var(--secondary-550);
	--sfm-upload-status-loading-color: var(--information1);
	--sfm-upload-status-progress-bar-color: var(--secondary-600);
	--sfm-upload-status-progress-bar-loading-color: var(--information1);
	--sfm-upload-text-color: var(--secondary1);
	--slider-pager-color: var(--secondary-250);
	--slider-pager-hover-color: var(--brand);
	--slider-pager-selected-color: var(--brand);
	--snapshot-list-item-hover-bg: var(--secondary);
	--tag-background-color: var(--secondary-350);
	--tag-focussed-background-color: var(--secondary2);
	--tag-focussed-remove-color: var(--secondary-550);
	--tag-focussed-remove-hover-color: var(--secondary-450);
	--tag-hover-background-color: var(--secondary-250);
	--tag-hover-text-color: var(--secondary-550);
	--tag-remove-color: var(--secondary-550);
	--tag-remove-hover-color: var(--secondary-350);
	--tag-text-color: var(--secondary-550);
	--text-editor-background-color: var(--secondary);
	--text-editor-disabled-background-color: var(--secondary-450);
	--text-editor-error-border-color: var(--error2);
	--toggle-checked-span-before-background-color: var(--secondary-350);
	--toggle-checked-span-before-border-color: var(--secondary-350);
	--tooltip-background-color: var(--secondary);
	--tooltip-border-color: var(--secondary4);
	--tooltip-tip-after-color: var(--secondary);
	--two-fa-hint-color: var(--secondary-350);
	--upsell-teaser-background-color: var(--secondary-450);
	--upsell-teaser-action-background-color: var(--secondary);
	--upsell-teaser-action-border-color: var(--secondary-250);
	--upsell-topbar-background-color: var(--secondary-150);
	--upsell-topbar-text-color: var(--secondary-550);
	--upsell-topbar-hide-link-color: var(--upsell-topbar-text-color);
	--upsell-topbar-hide-link-active-color: var(--primary);
	--upsell-topbar-hide-link-hover-color: var(--primary2);
}
