.sharemanager-header {
	.sharemanager-toolbar {
		margin-top: 7px;
		display: flex;
	}

	.file-type-count {
		flex: 1;

		line-height: 20px;
		font-weight: 600;
	}

	.sharemanager-sort-flyout {
		@extend .fm-sort-flyout;
	}
}
